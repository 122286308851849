
import { defineComponent, ref, onMounted, reactive, computed, getCurrentInstance } from 'vue'
import moment from 'moment'
import { useRouter, useRoute } from 'vue-router'
import axiosService from '@/services/AxiosService'
import selectOptionService from '@/services/SelectOptionService'
import { AgGridVue, } from "ag-grid-vue3"
import { GridReadyEvent, GridApi } from "ag-grid-community";
import VPagination from "@hennge/vue3-pagination"
import { User, UserInGrid } from '@/model'
import { AxiosResponse } from 'axios'
import "@/sass/pagination.scss"

export default defineComponent({
  name: 'user-statistic',
  components: {
    AgGridVue,
    VPagination,    
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const app = getCurrentInstance()
    const users = reactive([] as Array<UserInGrid>)
    const keyword = ref('')
    const deleteConfirmModal = ref<any>(null)
    const confirmToDelete = ref(false)
    let gridApi : GridApi
    let gridColumnApi = null as any
    const columnColumnDefs = [
        { headerName: "序号", field: "serialNo", width:120,checkboxSelection: true, headerCheckboxSelection: true },
        { headerName: "姓名", field: "fullName", width:250, resizable:true },
        { headerName: "职位", field: "title",width:250, resizable:true },
        { headerName: "手机号码", field: "mobile", resizable:true },
        { headerName: "邮箱", field: "email",width:250, resizable:true },
        { headerName: "所属部门", field: "department.name", resizable:true },
        { headerName: "电话", field: "telephoneNo", resizable:true },
        { headerName: "", flex:1 } // placeholder column
    ]
    let recordCount = ref(1)
    const currentPage = ref(1)
    const pageSize = ref(30)
    const pageCount = computed(() => Math.ceil(recordCount.value / pageSize.value))
    function currengPageChanged(page: number)
    {
      selectedItems.value.splice(0, selectedItems.value.length)
      currentPage.value = page
      searchRecords()
    }
    let selectedItems = ref([] as Array<UserInGrid>)

    function toast(config: any) {
      if (app) {
        const vaToast = app.appContext.config.globalProperties.$vaToast
        vaToast.init(config)
      }
    }

    function redirect2ProjectInfo() {
      router.push(`/user/${selectedItems.value[0].id}/corporate`)
    }

    function onSelectionChanged() {
      selectedItems.value.splice(0, selectedItems.value.length)
      Array.prototype.push.apply(selectedItems.value, gridApi.getSelectedRows() as Array<UserInGrid>)
    }

    function onGridReady(params: GridReadyEvent) {
      gridApi = params.api
      gridColumnApi = params.columnApi
      searchRecords()
    }

    function searchRecords() {
      let $filter = ''
      if (keyword.value != '') {
        $filter = `&$filter=contains(fullname,'${keyword.value}')`
      }
      gridApi.showLoadingOverlay();
      axiosService.get(`/odata/ownedusers/$count?${$filter}`).then((res : AxiosResponse<number>) => {
        recordCount.value = res.data
      })

      axiosService.get(`/odata/ownedusers?&$orderby=createdOn desc&$expand=department($select=id,name),userroles($expand=role($select=id,name,displayName))${$filter}&$top=${pageSize.value}&$skip=${(currentPage.value - 1) * pageSize.value}`).then(res => {
        users.splice(0, users.length)
        Array.prototype.push.apply(
          users,
          res.data.value.map((c: User, index: number) => {
            return ({
              id: c.id,
              serialNo: ((currentPage.value - 1) * pageSize.value ) + (index + 1),
              fullName: c.fullName,
              userName: c.userName,
              title: c.title,
              mobile: c.mobile,
              telephone: c.telephone,
              email: c.email,
              departmentId: c.departmentId,
              department: c.department,
              createdOn: c.createdOn,
              rolesInString: c.userRoles.map(ur => ur.role.displayName).join(', ')
            }) as UserInGrid
          }
        ))
        gridApi.setRowData(users)
        gridApi.hideOverlay()
      })
    }

    function selectionChange(evt: any)
    {
      selectedItems.value = evt.currentSelectedItems
    }



    return {
      name: 'corporate',
      keyword,
      columnColumnDefs,
      redirect2ProjectInfo,
      searchRecords,
      selectedItems,
      selectionChange,
      currentPage,
      onGridReady,
      onSelectionChanged,
      pageCount,
      pageSize,
      currengPageChanged,
      deleteConfirmModal,
      confirmToDelete
    }
  }
})
